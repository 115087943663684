    .navigation {
        background-color: #ee2024;
        width: 97%;
        height: 50px;
        margin: 0 auto;
        box-sizing: border-box;
        border-radius: 0 0 10px 10px;
        @media screen and (max-width: 700px) {
            border-radius: 0 0 5px 5px;
        }

        ul {
            width: 97%;
            list-style-type: none;
            position: absolute;
            margin: 0 auto;
            padding: 0 0.1%;
            overflow: hidden;
            height: 100%;

            li {
                width: 50%;
                text-transform: uppercase;
                text-align: center;
                overflow: hidden;
                margin: 0;
                padding: 0;
                float: left;
                img {
                    vertical-align: text-top;
                    height: 21px;
                    width: auto;
                    padding: 0;
                    padding-right: 12px;
                    padding-top: 4px;
                    margin: 0;
                    @media screen and (max-width: 700px) {
                        height: 16px;
                        padding-right: 5px;
                    }
                }
            }
        }

        .navLink {
            width: 98%;
            height: 44px;
            display: block;
            border: 1px solid #961619;
            border-radius: 10px;
            box-sizing: border-box;
            line-height: 1;
            float: center;
            text-align: center;
            padding: 0;
            padding-top: 4px;
            margin: 2px 4px;
            color: white;
            font-size: larger;
            @media screen and (max-width: 700px) {
                font-size: 1rem;
                border-radius: 5px;
            }
            text-decoration: none;
            &:hover {
                background-color: #2e3192;
            }
        }
        :global(.active) {
            border: 1px solid #d68a45;
        }

    }