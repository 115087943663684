.header {
    position: relative;
    overflow: hidden;
    z-index: 100;
    filter: drop-shadow(2px 2px 8px);
    @media screen and (max-width: 700px) {
        filter: drop-shadow(1px 1px 4px);
    }
    
    .zenderbalk {
        height: 50px;
        @media screen and (max-width: 700px) {
            height: 30px;
          }
        width: 97%;
        overflow: hidden;
        padding: 0;
        margin: 0;
        text-align: right;
        .zenderlogo {
            height: 50px;
            width: auto;
            filter: drop-shadow(0px 0px 0px);
            @media screen and (max-width: 700px) {
                height: 30px;
              }
        }
    }

    .headerbalk {
        aspect-ratio: 170/23;
        width: 97%;
        margin: 0 auto;
        padding: 0;
        background: url('../../assets/images/background-header.jpg') top left / cover no-repeat;
        border-radius: 10px 10px 0 0;
        @media screen and (max-width: 700px) {
            border-radius: 5px 5px 0 0;
        }
    }

    h1 {
        margin: 0;
        margin-top: 1%;
        margin-right: 5%;
        padding: 1%;
        width: 70%;
        float: right;
        img {
            width: 100%;
            height: auto;
        }
    }
    .speculasieTopLeft {
        pointer-events:none;
        display: block;
        padding: 0;
        margin: 0;
        width: 15%;
        max-width: 400px;
        position: absolute;
        top: 0;
        margin-top: 20px;
        @media screen and (min-width: 700px) {
          margin-top: 35px;
        }
        @media screen and (min-width: 1200px) {
            margin-top: 30px;
        }
        @media screen and (min-width: 1400px) {
            margin-top: 22px;
        }
        margin-left: 6%;
        z-index: 500;
        clear: none;
    }

}