.container {
    display: table;
	width: 100%;
	min-height: 100vh;

    .row {
        display: table-row;
        width: 100%;
        min-height: 100vh;
    }

	.leftmargin,
	.rightmargin {
        display: table-cell;
        height: 100%;
		min-height: 100vh;
		width: 5%;
		@media screen and (min-width: 1800px) {
			width: calc((100% - 1600px) / 2);
		}
	}

	.leftmargin {
		background: url("../../assets/images/speculaasrand.png") repeat-y top right -50px;
		@media screen and (max-width: 1000px) {
			background: url("../../assets/images/speculaasrand-klein.png") repeat-y
				top right -25px;
		}
        @media screen and (max-width: 700px) {
			background: url("../../assets/images/speculaasrand-klein.png") repeat-y
				top right -15px;
        }
	}

	.rightmargin {
		background: url("../../assets/images/speculaasrand.png") repeat-y top left -50px;
		@media screen and (max-width: 1000px) {
			background: url("../../assets/images/speculaasrand-klein.png") repeat-y
				top left -25px;
		}
        @media screen and (max-width: 700px) {
			background: url("../../assets/images/speculaasrand-klein.png") repeat-y
				top left -15px;
		}
	}

	.main {
        display: table-cell;
		min-height: 100vh;
		background-color: white;
		width: 90%;
		margin: 0 auto;
		padding-bottom: 50px;
		@media screen and (min-width: 1800px) {
			width: 1600px;
		}
		background: url("../../assets/images/speculaasrand.png") repeat-y top left -50px,
			url("../../assets/images/speculaasrand.png") repeat-y top right -50px,
			white;
		@media screen and (max-width: 1000px) {
			background: url("../../assets/images/speculaasrand-klein.png") repeat-y
					top left -25px,
				url("../../assets/images/speculaasrand-klein.png") repeat-y top right -25px,
				white;
		}
        @media screen and (max-width: 700px) {
			background: url("../../assets/images/speculaasrand-klein.png") repeat-y
					top left -35px,
				url("../../assets/images/speculaasrand-klein.png") repeat-y top right -35px,
				white;
		}
	}
}
