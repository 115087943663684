.textblock {
    width: 80%;
    margin: 0 auto;
    font-size: 1.35rem;
    @media screen and (max-width: 700px) {
        font-size: 1.15rem;
    }
}

.pageheader {
    font-size: 1.8rem;
    @media screen and (max-width: 700px) {
        font-size: 1.17rem;
    }
}

input[type="text"] {
    font-family: "skolar-sans-latin", sans-serif;
    width: 100%;
    height: 2.5rem;
    font-size: 1.35rem;
    padding: 0.5rem;
    margin: 0.5rem 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    overflow: visible;
    @media screen and (max-width: 700px) {
        height: 2rem;
        font-size: 1.15rem;
    }
}


textarea {
    font-family: "skolar-sans-latin", sans-serif;
    width: 100%;
    font-size: 1.35rem;
    padding: 0.5rem;
    margin: 0.5rem 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    @media screen and (max-width: 700px) {
        height: 2rem;
        font-size: 1.15rem;
    }
}

.presentator {
    max-width: 97%;
    border-radius: 10px;
    display: block;
    margin: 20px auto;
}

.lijst {
    aspect-ratio: 180 / 114;
    box-sizing: border-box;
    width: 100%;
    margin: 20px auto;
    padding: 8.5% 0 0 0.5%;
    background: url('../assets/images/lijst.png') top left / cover no-repeat;
    @media screen and (min-width: 1000px) {
        width: 90%;
        padding: 7.85% 0 0 0.5%;
    }

    .lijstcontent {
        aspect-ratio: 16 / 9;
        box-sizing: border-box;
        margin: 0 auto;
        width: 81%;
        iframe {
            width: 100%;
            height: 100%;
        }
    }
}

